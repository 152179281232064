<template>
  <apex-chart
    type="bar"
    :height="height"
    :options="options"
    :series="series"
  />
</template>

<script>
// Import the chart component from package
import ApexChart from "vue-apexcharts"

// Import lodash function to deep merge objects
import merge from "lodash/merge"

// Import default chart settings
import apexChartBaseOptions from "@/json/apexChartBaseOptions.js"

// Export the component
export default {
  // Name of the component
  name: "CategoriesBreakdownChart",

  // Register the children components
  components: {
    ApexChart
  },

  // Incoming data from parent
  props: {
    height: {
      type: Number,
      default: 350
    },

    colors: {
      type: Array,
      required: true
    },

    series: {
      type: Array,
      required: true
    },

    xaxis: {
      type: Array,
      required: true
    },

    yaxis: {
      type: String,
      required: true
    },

    isYAxisPercentage: {
      type: Boolean,
      default: false
    }
  },

  // Define computable properties
  computed: {
    /**
     * Get the chart configuration options
     *
     * @returns {Object}
     */
     options() {
      return merge(apexChartBaseOptions(), {
        dataLabels: {
          enabled: false
        },
        colors: this.colors,
        stroke: {
          show: true,
          width: 3,
          colors: ["transparent"]
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "50%",
            borderRadius: 8,
            borderRadiusApplication: 'end'
          },
        },
        yaxis: {
          type: "numeric",
          title: {
            text: this.yaxis,
          },
          labels: {
            formatter: (value) => this.isYAxisPercentage ? numeral.fPercent(value * 100) : nFormatter(value)
          }
        },
        xaxis: {
          categories: this.xaxis,
        },
        tooltip: {
          y: {
            formatter: (value) => this.isYAxisPercentage ? numeral.fPercent(value * 100) : nFormatter(value)
          }
        }
      })
    }
  },

  mounted() {
    window.setTimeout(() => {
      // To fix chart not loading at first @link: https://github.com/apexcharts/vue-apexcharts/issues/185#issuecomment-642836287
      window.dispatchEvent(new Event("resize"))
    }, 50)
  }
}
</script>
